import "./App.scss";
import Information from "./components/Information";
import Navigation from "./components/Navigation";
import Speisekarte from "./components/Speisekarte";

function App() {
  return (
    <div className="App">
      <Navigation />
      <div id="header"></div>
      <Information />
      <iframe
        id="googleMaps"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2564.9301493013345!2d8.725539116292031!3d49.99391902803424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd729bd0a9b399%3A0x842a028188509172!2sPizzeria%20Europa!5e0!3m2!1sde!2sde!4v1644014166564!5m2!1sde!2sde"
        loading="lazy"
      ></iframe>
      <Speisekarte />
    </div>
  );
}

export default App;
