import "../App.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRoute,
  faAddressBook,
  faClock,
  faConciergeBell,
} from "@fortawesome/free-solid-svg-icons";

export default function Information() {
  return (
    <div id="infoRow">
      <div className="infoBox">
        <FontAwesomeIcon icon={faRoute} />
        <h1>Phillipseicher Straße 2</h1>
        <h1>63303 Dreieich-Götzenhain</h1>
      </div>
      <div className="infoBox">
        <FontAwesomeIcon icon={faAddressBook} />
        <h1>06103 - 81 488</h1>
        <h1>epifanino@googlemail.com</h1>
      </div>
      <div className="infoBox">
        <FontAwesomeIcon icon={faClock} />
        <h1>Täglich außer Dienstags</h1>
        <h1>11:30 - 14:30 & 17:30 - 24:00</h1>
      </div>
      <div className="infoBox">
        <FontAwesomeIcon icon={faConciergeBell} />
        <h1>Lieferservice in Dreieich</h1>
        <h1>18:00 - 21:00</h1>
      </div>
    </div>
  );
}
