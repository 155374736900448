import "./Block.scss";
import Speise from "../model/Speise";

export default function Block(props: any) {
  return (
    <div className="block">
      <div className="number">{props.data.getID()}</div>
      <div className="text">
        <div className="title">{props.data.getTitle()}</div>
        <div className="description">{props.data.getDescription()}</div>
      </div>
      <div className="price">
        {props.data.getPrice()}
        {" €"}
      </div>
    </div>
  );
}
