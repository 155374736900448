class Pizza {
  private id: number;
  private title: string;
  private description: string;
  private priceMedium: number;
  private priceLarge: number;

  constructor(
    id: number,
    title: string,
    description: string,
    priceMedium: number,
    priceLarge: number
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.priceMedium = priceMedium;
    this.priceLarge = priceLarge;
  }

  getID(): number {
    return this.id;
  }

  getTitle(): string {
    return this.title;
  }

  getDescription(): string {
    return this.description;
  }

  getPriceMedium(): string {
    return (Math.round(this.priceMedium * 100) / 100).toFixed(2);
  }

  getPriceLarge(): string {
    return (Math.round(this.priceLarge * 100) / 100).toFixed(2);
  }
}

export default Pizza;
