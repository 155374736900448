import "../App.scss";

import Card from "react-bootstrap/Card";

import dessertImg from "../assets/dessert.jpg";
import fischImg from "../assets/fisch.jpg";
import fleischImg from "../assets/fleisch.jpg";
import nudelnImg from "../assets/pasta.jpg";
import pizzaImg from "../assets/pizza.jpg";
import reisImg from "../assets/risotto.jpg";
import salatImg from "../assets/salat.jpg";
import vorspeiseImg from "../assets/starter.jpg";
import suppenImg from "../assets/suppen.jpg";
import Pizza from "../model/Pizza";
import Speise from "../model/Speise";
import Block from "./Block";
import PizzaBlock from "./PizzaBlock";

export default function Speisekarte() {
  const vorspeise: Speise[] = [
    new Speise(1, "Antipasto Misto (gemischte Vorspeise)", "Parmaschinken, Salami, Parmesankäse, Oliven", 15.0),
    new Speise(2, "Carpaccio (Kalbfleisch)", "Kalbfleischscheiben in Olivenöl, Zitrone und Sellerie", 14.0),
    new Speise(3, "Piatto Vegetario (Gemüseteller)", "Auberginen bzw. Zuchini, Broccoli und weitere Gemüsearten", 14.0),
    new Speise(4, "Insalata di Pesce (Fischsalat)", "Tintenfisch in Knoblauchsoße mit Sardellenfilets und Meeresfrüchten", 16.0),
    new Speise(5, "Insalata Campagnola Petto D'Anatra", "Feldsalat mit Entenbrust", 14.0),
    new Speise(6, "Funghi Pleos alla Griglia", "Austernpilze gegrillt", 13.0),
    new Speise(191, "Vitello Tonnato", "Kalbfleisch mit Thunfischsoße", 14.0),
    new Speise(192, "Scampetti alla Chef", "Kleine Krabben alla Chef", 16.0),
    new Speise(193, "Prosciutto Parma Melone", "Parmaschinken mit Melonenecken", 14.0),
    new Speise(194, "Rugola Con Parmiggiano", "Salat mit Parmesankäse und Tomaten", 8.5),
    new Speise(195, "Rugola Con Scampetti E Aglio", "Salat mit kleinen Krabben in Knoblauchsoße", 15.0),
  ];

  const suppen: Speise[] = [
    new Speise(104, "Zuppa di Cipolla", "Zwiebelsuppe", 6),
    new Speise(7, "Zuppa die Pomodoro", "Tomatensuppe", 6),
    new Speise(8, "Minestrone", "Gemüsesuppe", 7),
    new Speise(9, "Zuppa di Pesce", "Fischsuppe", 9),
    new Speise(10, "Tortellini al brodo di Manzo", "Tortellini in Rindfleischsuppe", 7),
    new Speise(105, "Stracciatella", "Boullion mit Ei", 7),
  ];

  const pizza: Pizza[] = [
    new Pizza(11, "Mare", "mit Meeresfrüchten", 9, 11),
    new Pizza(12, "Venezia", "Schinken, Peperoniwurst, Pilze, Artischocken", 9, 11),
    new Pizza(13, "Spezial", "Salami, Peperoniwurst, Pilze", 9, 11),
    new Pizza(14, "Selentina", "Schinken, Sardellen, Muscheln, Kapern", 9, 11),
    new Pizza(15, "Messapico", "Salami, Schinken, Artischocken, Muscheln, Pilze", 9, 11),
    new Pizza(16, "4 Stagioni", "Schinken, Peperoniwurst, Muscheln, Artischocken", 9, 11),
    new Pizza(17, "Capriccosa", "Schinken, Peperoniwurst, Pilze, Artischocken, Paprika", 9, 11),
    new Pizza(18, "Napoletana", "Salami, Peperoniwurst, Pilze, Artischocken", 9, 11),
    new Pizza(19, "Milano", "Schibken, Peperoniwurst, Pilze", 9, 11),
    new Pizza(20, "Pugliese", "Peperoniwurst, Artischocken, Sardellen, Pilze", 9, 11),
    new Pizza(21, "Romana", "Artischocken, Sardellen, Muscheln, Pilze", 9, 11),
    new Pizza(22, "Remini", "Salami, Peperoniwurst, Zwiebeln, Pilze", 9, 11),
    new Pizza(23, "Vegetaria", "Artischocken, Paparika, Zwiebeln, Pilze", 9, 11),
    new Pizza(24, "Carovigno", "Schinken, Peperoniwurst, Pilze, Paprika, Zwiebeln", 9, 11),
    new Pizza(25, "Bella Italia", "Schinken, Peperoniwurst, Pilze, Ei, Artischocken", 9, 11),
    new Pizza(26, "Europa", "Salami, Schinken, Peperoniwurst, Artischocken, Sardellen, Muscheln, Pilze", 9, 11.5),
    new Pizza(27, "Pizza Mix (scharf)", "Salami, Schinken, Peperoniwurst, Artischocken, Sardellen, Muscheln, Pilzze, Zwiebeln, Paprika", 9, 11.5),
    new Pizza(28, "Margherita", "", 8, 9),
    new Pizza(29, "Pizza Salami", "", 8, 9),
    new Pizza(30, "Pizza Peperoniwurst", "", 8, 9),
    new Pizza(31, "Pizza Schinken", "", 8, 9),
    new Pizza(32, "Pizza Artischocken", "", 8, 9),
    new Pizza(33, "Pizza Muscheln", "", 8, 9),
    new Pizza(34, "Pizza Pilze", "", 8, 9),
    new Pizza(35, "Pizza Contadina", "Auberginen, Zucchini, Paprika", 9, 11),
    new Pizza(36, "Pizza Tricolore", "Spinat, Zwiebeln, Paprika", 9, 11),
    new Pizza(37, "Pizza O`Sole Mio (scharf)", "Broccoli, Ei, Peperoni", 9, 11),
    new Pizza(138, "Pizza Grudaiola", "Parmaschinken, Rucola, Parmiggiano, Tomaten", 9, 11.5),
  ];

  const salat: Speise[] = [
    new Speise(38, "Kleiner Salat", "", 6),
    new Speise(39, "Italienischer Salat", "Salat, Tomaten, Gurken, Thunfisch, Ei", 7.5),
    new Speise(40, "Salat Mix", "Salat, Tomaten, Gurken, Thunfisch, Ei, Schinken, Käse", 8.5),
    new Speise(41, "Salat alla Chef", "Salat, Tomaten, Gurken, Radieschen, Karotten, Zwiebeln, Oliven, Thunfisch", 8.5),
    new Speise(42, "Salat Europa", "Tomaten, Mozzarella, Basilikum und Pizzateig", 8.5),
  ];

  const nudeln: Speise[] = [
    new Speise(43, "Spaghetti Napoletana", "Spaghetti in Tomatensauce", 9),
    new Speise(44, "Spaghetti Bolognese", "Spaghetti in Hackfleischsauce", 10),
    new Speise(45, "Spaghetti Carbonara", "Spaghetti in Sahnesauce, Ei, Vorderschinken", 10),
    new Speise(46, "Spaghetti al pesto", "Spaghetti in Kräutersauce", 10),
    new Speise(47, "Spaghetti al Radichio", "Spaghetti in Tomaten-Sahnesauce und Radiccio", 10),
    new Speise(48, "Rigatoni al Forno", "Makkaroni in Sahnesauce mit Käse überbacken", 10),
    new Speise(49, "Rigatoni alla Chef", "Makkaroni und Sahne- Tomatensauce, Champignons und Oregano", 10),
    new Speise(50, "Rigatoni Pizzaiola (scharf)", "Makkaroni in Tomatensauce, Thunfisch, Kapern, Knoblauch", 10),
    new Speise(51, "Tortellini alla Panna", "Rortellini in Sahnesauce mit Vorderschinken", 10),
    new Speise(52, "Tagliatella alla Panna", "Grüne Bandnudeln in Sahnesauce mit Schinken", 10),
    new Speise(
      53,
      "Paglia e Fieno al Pmodoro con Mozzarella e Basilico",
      "Grüne und gelbe Bandnudeln in Tomatensauce mit Mozzarella, Käse und Basilikum",
      10
    ),
    new Speise(54, "Pennete all'arrabbiata (scharf)", "Nudeln in Tomatensauce mit Knoblauch", 10),
    new Speise(55, "Pennette Matrisciana (scharf)", "Nudeln in Tomatensauce, Speck und Zwiebeln", 10),
    new Speise(56, "Lasagne", "", 10),
    new Speise(57, "Gnocci Gorgonzola", "", 10),
    new Speise(58, "Ravioli gefüllt mit Broccoli", "In Tomatensauce", 11.5),
    new Speise(59, "Ravioli gefüllt mit Spinat", "In Tomatensahnesauce", 11.5),
    new Speise(60, "Combinazione", "Gemischte Nudelplatte", 11.5),
    new Speise(61, "Rigatno alla Buttara (scharf)", "Makkaroni mit Tomaten, Ei und Oliven", 10),
    new Speise(62, "Spaghetti alla Cacciatora", "Spaghetti mit Pilzen, Speck und Erbsen", 10),
    new Speise(63, "Penette Spinaci", "Nudeln in Gorgonzolasauce mit Spinat", 10),
    new Speise(64, "Pennette Brocoli", "Nudeln in Knoblauchsauce, Broccoli und Olivenöl", 10),
    new Speise(65, "Tagliolini al Salmone", "Bandnudeln mit Lachs", 11),
    new Speise(68, "Spaghetti Aglio e Olio Peperoncino (scharf)", "Spaghetti in Knoblauch und Öl", 10),
    new Speise(69, "Rigatoni alla Mafiosa (scharf)", "Makkaroni in Tomatensauce, Auberginen und Mozzarella", 10),
    new Speise(70, "Spaghetti Boscaiola", "Spaghetti in Tomatensauce mit Oliven, Paprika und Zwiebeln", 10),
    new Speise(71, "Tagliatelle Tricolore", "grüne und gelbe Bandnudeln in Toamtensahnesauce mit Spinat", 10),
    new Speise(72, "Triangoli alla Mamma (scharf)", "Triangoli in Tomatensacue mit Zucchini", 10),
  ];

  const reis: Speise[] = [
    new Speise(66, "Risotto al Procini", "Reis mit Steinpilzen", 11.5),
    new Speise(67, "Risotto Vegetario", "Reis auf vegetarische Art", 11.5),
  ];

  const fisch: Speise[] = [
    new Speise(81, "Cozze al Vino bianco e Aglio", "Muscheln in weißweinsauce mit Knoblauch", 12),
    new Speise(82, "Cozze al Pmodore e Aglio", "Muscheln in Tomatensauce mit Knoblauch", 12),
    new Speise(83, "Risotto Frutti di Mare", "Reis mit Meeresfrüchten", 13.5),
    new Speise(84, "Spaghetti Frutti di Mare", "Spaghetti mit Meeresfrüchten", 13.5),
    new Speise(85, "Ravioli Ripieni di Pesce", "Ravioli mit Fisch gefüllt", 13.5),
    new Speise(86, "Coda di Rospo", "Seeteufel in Tomatensacue mit Reis", 22),
    new Speise(87, "Seppie Ripiene", "gefüllter Tintenfisch mit Parmesan, Ei, Knoblauch und gebackenen Kartoffeln", 22),
    new Speise(88, "Scampi al Vino bianco Aglio con Spaghetti", "Scampi in Weißweinsauce mit Spaghetti und Knoblauch", 22),
    new Speise(89, "Scampi al pomodoro Aglio con Spaghetti", "Scampi in Tomatensauce mit Spaghetti und Knoblauch", 23),
    new Speise(90, "Scampi alla Grilia con Insalata", "Scampi vom Grill mit Salat", 23),
    new Speise(91, "Scampi aglio e Olio Peperoncino con Spaghetti", "Scapi mit Spaghetti, Knoblauch und Öl (scharf)", 23),
    new Speise(92, "Combinazioni di Pesce", "Gegrillte Scampi, frittierter Tintenfisch, Seeteufel und Reis mit Meeresfrüchten (je nach Saison)", 27),
    new Speise(197, "Sogliola", "Seezunge nach Wunsch zubereitet mit Beilage", 25),
    new Speise(198, "Orate", "Orate nach Wunsch zubereitet mit Beilage", 23),
    new Speise(199, "Branzino", "Seewolf nach Wunsch zubereitet mit Beilage", 23),
    new Speise(200, "Steinbutt", "", 25),
  ];

  const fleisch: Speise[] = [
    new Speise(73, "Scaloppina Gorgonzola", "Kalbfleisch in Gorgonzolasauce mit Spaghetti", 22),
    new Speise(74, "Scaloppina Europa", "Kalbfleisch in Sahnesauce mit Spaghetti und grünem Pfeffer", 22),
    new Speise(75, "Scaloppina Italia", "Kalbfleisch in Sahnesauce mit Spaghetti und Pilzen", 22),
    new Speise(76, "Scaloppina Pizzaiola", "Kalbleisch mit Spaghetti, Olivem Kapern und Knoblauch (scharf)", 22),
    new Speise(77, "Scaloppina al Vino bianco", "Kalbfleisch in Weißweinsauce mit Spaghetti und Pilzen", 22),
    new Speise(78, "Jägerschnitzel", "mit Pommes und Salat", 15),
    new Speise(79, "Wiener Schnitzel", "Kalb oder Schwein, mit Pommes und Salat", 15),
    new Speise(80, "Salti in Bocca alla Chef", "Kalbfleisch im Ofen gebacken, mit Spaghetti in Weißweinsauce mit Schinken und Käse", 22),
    new Speise(401, "Cotolette D'Agnello", "Lammkotelett gegrillt mit Beilage", 22),
    new Speise(402, "Fegato di Vitellino alla Veneziana", "Kalbsleber venezianischer Art", 22),
    new Speise(403, "Quaglie al fonro", "Im Ofen gebackene Wachteln in Weißweinsauce mit Beilage", 21),
    new Speise(404, "Rumpsteak", "", 23),
    new Speise(405, "Rinderfilet", "", 25),
    new Speise(406, "Kalbskotelett", "", 25),
  ];

  const dessert: Speise[] = [
    new Speise(96, "Eis Europa", "Vanille, Schokolade, Erdbeere, Zitrone", 7),
    new Speise(97, "Cassata", "", 6.5),
    new Speise(98, "Tartufo", "", 6.5),
    new Speise(99, "Tiramisu", "", 6.5),
    new Speise(100, "Zabaglione mit Vanilleeis", "", 6.5),
    new Speise(101, "Eis Combinazione", "Tartufo, Cassata, Tiramisu", 15),
  ];

  return (
    <div id="speisekarte">
      <h1>Speisekarte</h1>
      <Card id="vorspeise">
        <Card.Img
          variant="top"
          src={vorspeiseImg}
        />
        <Card.Body>
          <Card.Title>Vorspeisen</Card.Title>
          <div className="liste">
            {vorspeise.map((elem: Speise) => (
              <Block
                data={elem}
                key={elem.getID()}
              />
            ))}
          </div>
        </Card.Body>
      </Card>

      <Card id="suppe">
        <Card.Img
          variant="top"
          src={suppenImg}
        />
        <Card.Body>
          <Card.Title>Suppen</Card.Title>
          <div className="liste">
            {suppen.map((elem: Speise) => (
              <Block
                data={elem}
                key={elem.getID()}
              />
            ))}
          </div>
        </Card.Body>
      </Card>

      <Card id="salat">
        <Card.Img
          variant="top"
          src={salatImg}
        />
        <Card.Body>
          <Card.Title>Salate</Card.Title>
          <div className="liste">
            {salat.map((elem: Speise) => (
              <Block
                data={elem}
                key={elem.getID()}
              />
            ))}
          </div>
        </Card.Body>
      </Card>

      <Card id="pizza">
        <Card.Img
          variant="top"
          src={pizzaImg}
        />
        <Card.Body>
          <Card.Title>Pizza</Card.Title>
          <div className="liste">
            {pizza.map((elem: Pizza) => (
              <PizzaBlock
                data={elem}
                key={elem.getID()}
              />
            ))}
          </div>
        </Card.Body>
      </Card>

      <Card id="nudel">
        <Card.Img
          variant="top"
          src={nudelnImg}
        />
        <Card.Body>
          <Card.Title>Nudeln</Card.Title>
          <div className="liste">
            {nudeln.map((elem: Speise) => (
              <Block
                data={elem}
                key={elem.getID()}
              />
            ))}
          </div>
        </Card.Body>
      </Card>

      <Card id="reis">
        <Card.Img
          variant="top"
          src={reisImg}
        />
        <Card.Body>
          <Card.Title>Reisgerichte</Card.Title>
          <div className="liste">
            {reis.map((elem: Speise) => (
              <Block
                data={elem}
                key={elem.getID()}
              />
            ))}
          </div>
        </Card.Body>
      </Card>

      <Card id="fisch">
        <Card.Img
          variant="top"
          src={fischImg}
        />
        <Card.Body>
          <Card.Title>Frische Meeresfrüchte & Fisch</Card.Title>
          <div className="liste">
            {fisch.map((elem: Speise) => (
              <Block
                data={elem}
                id={elem.getID()}
              />
            ))}
          </div>
        </Card.Body>
      </Card>

      <Card id="fleisch">
        <Card.Img
          variant="top"
          src={fleischImg}
        />
        <Card.Body>
          <Card.Title>Fleisch</Card.Title>
          <div className="liste">
            {fleisch.map((elem: Speise) => (
              <Block
                data={elem}
                id={elem.getID()}
              />
            ))}
          </div>
        </Card.Body>
      </Card>

      <Card id="dessert">
        <Card.Img
          variant="top"
          src={dessertImg}
        />
        <Card.Body>
          <Card.Title>Dessert</Card.Title>
          <div className="liste">
            {dessert.map((elem: Speise) => (
              <Block
                data={elem}
                id={elem.getID()}
              />
            ))}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
