import Pizza from "../model/Pizza";
import "./Block.scss";

export default function PizzaBlock(props: any) {
  return (
    <div className="block">
      <div className="number">{props.data.getID()}</div>
      <div className="text">
        <div className="title">{props.data.getTitle()}</div>
        <div className="description">{props.data.getDescription()}</div>
      </div>
      <div className="price">
        <div>
          <i>mittel </i>
          {props.data.getPriceMedium() + " €"}
        </div>
        <div>
          <i>groß </i>
          {props.data.getPriceLarge() + " €"}
        </div>
      </div>
    </div>
  );
}
