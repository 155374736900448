import { useState } from "react";
import { Navbar, Nav, NavDropdown, Modal, Button } from "react-bootstrap";
import "../App.scss";

export default function Navigation() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div id="navigation">
      <Navbar
        collapseOnSelect
        expand="md"
        bg="light"
        variant="light"
        fixed="top"
      >
        <Navbar.Brand href="#header">Pizzeria Europa</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="#header">Startseite</Nav.Link>
            <NavDropdown title="Speisekarte" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#vorspeise">Vorspeisen</NavDropdown.Item>
              <NavDropdown.Item href="#suppe">Suppen</NavDropdown.Item>
              <NavDropdown.Item href="#salat">Salate</NavDropdown.Item>
              <NavDropdown.Item href="#pizza">Pizza</NavDropdown.Item>
              <NavDropdown.Item href="#nudel">Nudeln</NavDropdown.Item>
              <NavDropdown.Item href="#reis">Reisgerichte</NavDropdown.Item>
              <NavDropdown.Item href="#fisch">Fisch</NavDropdown.Item>
              <NavDropdown.Item href="#fleisch">Fleisch</NavDropdown.Item>
              <NavDropdown.Item href="#dessert">Dessert</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            <Nav.Link onClick={handleShow}>Impressum</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>Pizzeria Europa</Modal.Header>
        <div id="impressum">
          <div className="blockInfo">
            <h1>Impressum</h1>

            <div className="innerBlock">
              <h2>Inhaber</h2>
              <h3>Giovanni Epifani</h3>
            </div>

            <div className="innerBlock">
              <h2>Adresse</h2>
              <h3>Philipseicher Straße 2</h3>
              <h3>63303 Dreieich</h3>
            </div>

            <div className="innerBlock">
              <h2>Kontakt</h2>
              <h3>epifanino@googlemail.com</h3>
              <h3>06103 / 81 488</h3>
            </div>

            <div className="innerBlock">
              <h2>Registergericht</h2>
              <h3>AG Langen</h3>
            </div>

            <div className="innerBlock">
              <h2>
                Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz
              </h2>
              <h3>DE83094129655</h3>
            </div>
          </div>

          <div className="blockInfo">
            <div className="innerBlock">
              <h1>Disclaimer</h1>
              <h3 style={{ textAlign: "justify" }}>
                Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
                Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
                können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter
                sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
                Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
                bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                verpflichtet, übermittelte oder gespeicherte fremde
                Informationen zu überwachen oder nach Umständen zu forschen, die
                auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                Entfernung oder Sperrung der Nutzung von Informationen nach den
                allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                Kenntnis einer konkreten Rechtsverletzung möglich. Bei
                Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
                diese Inhalte umgehend entfernen.
              </h3>
            </div>
          </div>

          <div className="blockInfo">
            <div className="innerBlock">
              <h1>Urheberrecht</h1>
              <h3 style={{ textAlign: "justify" }}>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                Downloads und Kopien dieser Seite sind nur für den privaten,
                nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
                dieser Seite nicht vom Betreiber erstellt wurden, werden die
                Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
                Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                werden wir derartige Inhalte umgehend entfernen.
              </h3>
            </div>
          </div>

          <div className="blockInfo">
            <div className="innerBlock">
              <h1>Datenschutz</h1>
              <h3 style={{ textAlign: "justify" }}>
                Die Nutzung unserer Webseite ist in der Regel ohne Angabe
                personenbezogener Daten möglich. Soweit auf unseren Seiten
                personenbezogene Daten (beispielsweise Name, Anschrift oder
                eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich,
                stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
                ausdrückliche Zustimmung nicht an Dritte weitergegeben. Wir
                weisen darauf hin, dass die Datenübertragung im Internet (z.B.
                bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
                kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
                Dritte ist nicht möglich. Der Nutzung von im Rahmen der
                Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur
                Übersendung von nicht ausdrücklich angeforderter Werbung und
                Informationsmaterialien wird hiermit ausdrücklich widersprochen.
                Die Betreiber der Seiten behalten sich ausdrücklich rechtliche
                Schritte im Falle der unverlangten Zusendung von
                Werbeinformationen, etwa durch Spam-Mails, vor.
              </h3>
            </div>
          </div>
        </div>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            style={{ margin: "0 auto" }}
          >
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
